<template>
  <div>
    <div class="loader-container" v-if="fetchProductStatusPending">
      Fetching product details...
    </div>
    <div class="main-wrap">
      <!-- main -->
      <main>
        <div class="main-section">
          <div class="container h-100 small">
            <router-link to="/" class="logo">
              <img :src="require('@/assets/img/logo.png')" alt="" />
            </router-link>
            <div class="before-data">
              <BaseButton tag="router-link" to="/dashboard">
                Dashboard
              </BaseButton>
              <BaseButton tag="router-link" to="/"> New Search </BaseButton>
              <template v-if="fetchProductStatusSuccess">
                <BaseButton @click.prevent="exportToJson">
                  <!--<img :src="require('@/assets/img/envelope.png')" alt="" />-->
                  Save as JSON
                </BaseButton>
                <BaseButton @click.prevent="exportToCsv">
                  <!--<img :src="require('@/assets/img/envelope.png')" alt="" />-->
                  Save as CSV
                </BaseButton>
              </template>
            </div>
            <template v-if="fetchProductStatusSuccess">
              <div class="data-table">
                <div class="head-part">
                  <div class="search-filter">
                    <div class="search-box">
                      <div class="input-group">
                        <input
                          v-model="filter"
                          type="text"
                          placeholder="filter results"
                        />
                        <button @click.prevent="addFilter">
                          <BaseIcon icon="search" />
                        </button>
                      </div>
                    </div>
                    <div
                      class="current-filter"
                      v-for="(filter, idx) of filters"
                      :key="`${filter}-${idx}`"
                    >
                      <button @click.prevent="removeFilter(filter)">
                        <BaseIcon icon="times" />
                      </button>
                      <div>
                        <span>
                          {{ filter }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="about-search">
                    <div class="data">
                      <h4>{{ product.matchedCodesCount }}</h4>
                      <span>Matched({{ product.matchedPercentage }}%)</span>
                    </div>
                    <div class="data">
                      <h4>{{ product.unmatchedCodesCount }}</h4>
                      <span
                        >Un-Matched({{ product.unmatchedPercentage }}%)</span
                      >
                    </div>
                  </div>
                </div>
                <div class="table-main">
                  <div class="data-table-inner">
                    <table class="table">
                      <thead>
                        <tr>
                          <td class="data-isrc">ISRC</td>
                          <td class="data-iswc">ISWC</td>
                          <td class="data-performer">Performer</td>
                          <td class="data-title">Title</td>
                          <td class="data-party">Party</td>
                          <td class="data-artist_name">Known As</td>
                          <td class="data-admin">Admin By</td>
                          <td class="data-role">Role</td>
                          <td class="data-society">Society</td>
                          <td class="data-ipi">IPI</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, idx) of filteredData"
                          :key="`${item.mlc_party_id}-${idx}`"
                        >
                          <td class="data-isrc">{{ item.isrc }}</td>
                          <td class="data-iswc">{{ item.iswc }}</td>
                          <td class="data-performer">{{ item.performers }}</td>
                          <td class="data-title">{{ item.title }}</td>
                          <td class="data-party">
                            {{ item.interested_party }}
                          </td>
                          <td class="data-artist_name">
                            {{ item.artist_name }}
                          </td>
                          <td class="data-admin">{{ item.party_admin }}</td>
                          <td class="data-role">{{ item.role }}</td>
                          <td class="data-society">{{ item.society }}</td>
                          <td class="data-ipi">{{ item.ipi }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </template>
            <div v-if="fetchProductStatusError">
              <BaseAlert
                >There was a problem while fetching product details.</BaseAlert
              >
            </div>
          </div>
        </div>
      </main>
      <!-- main end -->
    </div>
  </div>
</template>

<script>
import { downloadFile } from "@/helpers";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { IDLE, PENDING, SUCCESS, ERROR } from "@/constants/apiStatus";
import { fetchPlaylists } from "@/api/isrcApi";
import { fetchProductById } from "@/api/productApi";
import { onAuthStateChanged } from "@/api/authApi";
export default {
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filter: "",
      filters: [],
      product: null,
      fetchProductStatus: IDLE,
      publisherData: [],
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchProductStatus"),
    filteredData() {
      if (!this.filters.length) {
        return this.publisherData;
      }
      const filters = this.filters.map((s) => s.toLowerCase());
      return this.publisherData.filter((item) => {
        const title = String(item.title).toLowerCase();
        return filters.some((filter) => title.includes(filter));
      });
    },
  },
  async created() {
    if (!this.productId) {
      this.$router.push("/dashboard");
      return;
    }
    const user = await onAuthStateChanged();
    if (!user) {
      this.$router.push("/");
      return;
    }
    this.fetchPlaylistsData(this.productId);
  },
  methods: {
    async fetchPlaylistsData(productId) {
      this.fetchProductStatus = PENDING;
      try {
        const productSnap = await fetchProductById(productId);
        if (!productSnap.exists) {
          throw new Error("DOES_NOT_EXIST");
        }
        const product = productSnap.data();
        this.product = product;
        const playlists = await fetchPlaylists({
          isrc_ids: product.matchedCodes,
          productId,
        });

        this.publisherData = playlists;
        this.fetchProductStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.fetchProductStatus = ERROR;
      }
    },
    removeFilter(filter) {
      this.filters = this.filters.filter((item) => item !== filter);
    },
    addFilter() {
      const filter = this.filter;
      this.filter = "";
      this.filters.push(filter);
    },
    exportToJson() {
      downloadFile({
        data: JSON.stringify(this.filteredData),
        fileName: "publisherData.json",
        fileType: "text/json",
      });
    },
    exportToCsv() {
      let headers = [
        "isrc",
        "interested_party",
        "performers",
        "apple_artist_ids",
        "title",
        "iswc",
        "mlc_party_id",
        "ascap_party_id",
        "party_admin",
        "ipi",
        "society",
        "role",
        "artist_name",
        "last_updated",
      ].join(",");

      // Convert users data to a csv
      let publisherDataCsv = this.filteredData.reduce((acc, item) => {
        // const { id, name, surname, age } = user;
        acc.push(
          Object.values(item)
            .map((item) => `"${item}"`)
            .join(",")
        );
        return acc;
      }, []);

      downloadFile({
        data: [headers, ...publisherDataCsv].join("\n"),
        fileName: "publisherData.csv",
        fileType: "text/csv",
      });
    },
  },
};
</script>
<style src="../../apps/css/boostrap.min.css"></style>
<style src="../../apps/css/styles.css"></style>
<style lang="scss" scoped>
.loader-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-section {
  padding-top: 150px !important;
}

.before-data {
  display: flex;
  align-items: center;
  h4 + h4 {
    margin-left: 1rem;
  }
}

.table {
  table-layout: fixed;
}

.data-isrc {
  width: 200px;
}

.data-performer {
  width: 300px;
}

.data-title {
  width: 300px;
}

.data-party {
  width: 300px;
}

.data-artist_name {
  width: 300px;
}

.data-admin {
  width: 300px;
}

.data-role {
  width: 250px;
}

.data-society {
  width: 200px;
}

.data-ipi {
  width: 200px;
}

.data-iswc {
  width: 200px;
}

.lookup-error {
  margin-top: 1rem;
}
@media (max-width: 1399px) {
  .main-section .container .logo {
    width: 250px;
  }
  .main-section .container h2 {
    font-size: 30px;
  }
  .main-section .container form .form-inner textarea {
    height: 103px;
    margin-top: 30px;
    font-size: 15px;
    padding: 7px 25px;
    line-height: 1.9;
    margin-top: 20px;
  }
  .main-section .container form {
    margin-top: 60px;
    margin-bottom: 60px;
    max-width: 450px;
  }
  .default-button {
    font-size: 18px;
    height: 55px;
    padding: 0 27px;
  }
  .main-section .container form .form-inner .button {
    margin-top: 45px;
  }
  .main-section .container .footer-nav li {
    font-size: 15px;
    font-weight: 700;
  }
  .main-section {
    padding: 70px 0 60px;
  }
  .main-section .container form .form-inner textarea.small {
    height: 200px;
  }

  .main-section .data-show .ring svg {
    width: 330px;
  }
  .main-section .data-show {
    margin: 40px 0 60px;
  }

  .data-table .table-main table td {
    padding: 4px 30px 15px 30px;
  }
  .data-table .table-main table thead td {
    padding: 15px 30px 5px 30px;
  }
  .data-table .table-main table td:first-child {
    padding-left: 25px;
  }
}

@media (max-width: 1199px) {
  .data-table .table-main table thead td {
    font-size: 16px;
    padding: 10px 30px 6px 20px;
  }
  .data-table .table-main table td {
    font-size: 14px;
    padding: 4px 15px 8px 20px;
    font-weight: 400;
  }
  .data-table .table-main table td:first-child {
    padding-left: 15px;
  }
  .data-table .table-main table tbody td:first-child {
    border-right-width: 3px;
  }

  .data-table .head-part .about-search .data h4 {
    font-size: 30px;
  }
  .data-table .head-part .about-search .data span {
    font-size: 16px;
  }
  .data-table .head-part .about-search {
    gap: 35px;
  }
  .data-table .head-part .about-search .data {
    gap: 6px;
  }
  .data-table .head-part .search-box .input-group input[type="text"] {
    font-size: 17px;
    height: 42px;
    padding: 0 12px;
  }
  .data-table .head-part .search-filter .current-filter {
    font-size: 13px;
    padding: 4px 10px;
    height: 35px;
    gap: 3px;
  }
  .data-table .head-part .search-filter .current-filter button i {
    font-size: 15px;
  }
  .data-table .head-part {
    padding: 15px;
  }
}

@media (max-width: 991px) {
  .main-section .container h2 {
    font-size: 22px;
  }
  .default-button {
    font-size: 16px;
    height: 46px;
    padding: 0px 22px;
  }

  .main-section .data-show .ring svg {
    width: 220px;
  }
  .data-table .table-main table thead td {
    padding: 10px 0px 6px 12px;
  }
  .data-table .table-main table td {
    padding: 4px 0px 8px 12px;
  }
  .data-table .table-main .data-table-inner {
    min-width: 760px;
  }
  .data-table .table-main table tbody td:first-child {
    border-right: none;
  }
  .data-table .head-part .search-filter {
    width: 100%;
    padding-bottom: 10px;
  }
  .data-table .head-part .about-search {
    gap: 30px;
    width: 100%;
    justify-content: flex-start;
  }
  .before-data {
    margin: 50px 0 0;
  }
  .before-data h4 {
    font-size: 16px;
    gap: 10px;
  }
  .before-data h4 img {
    width: 25px;
  }

  .main-section .container .page-close {
    width: 20px;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 575px) {
  .main-section .data-show .ring svg {
    width: 160px;
  }
  .main-section .data-show .top {
    gap: 0;
  }

  .data-table .head-part .about-search .data {
    gap: 0;
    flex-direction: column;
  }
  .data-table .head-part .about-search .data h4 {
    margin-bottom: 0;
  }
  .data-table {
    margin-top: 10px;
  }
}

@media (max-width: 390px) {
  .main-section {
    padding: 40px 0 40px;
  }
  .before-data {
    margin: 30px 0 0;
  }
}
</style>